<div class="card flex flex-wrap justify-content-center gap-3">
  <span class="p-input-icon-left w-full">
    <i class="pi pi-search" (click)="searchIconClicked()"></i>
    <input
      class="search-input {{ searchInputClass }}"
      type="text"
      pInputText
      [(ngModel)]="searchValue"
      (input)="onSearchValueChange($event)"
      [placeholder]="placeholder | translate"
    />
  </span>
</div>
