import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

@Component({
  selector: 'app-search-input',
  standalone: true,
  imports: [FormsModule, TranslateModule],
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.scss',
})
export class SearchInputComponent {
  private searchSubject = new Subject<string>();
  @Input() placeholder!: string;
  @Input() debounceTime = 1000;
  @Input() searchInputClass = '';
  @Input() searchValue: string = '';

  @Output() searchChange = new EventEmitter<string>();
  @Output() searchIconClickedEmit = new EventEmitter<any>();

  constructor() {
    this.searchSubject
      .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
      .subscribe((value) => {
        this.searchChange.emit(value);
      });
  }

  onSearchValueChange(event: Event): void {
    const input = event.target as HTMLInputElement | null;
    if (input) {
      this.searchValue = input.value;
      this.searchSubject.next(input.value);
    }
  }

  searchIconClicked() {
    this.searchIconClickedEmit.emit();
  }

  clear(): void {
    this.searchValue = '';
    this.searchSubject.next(this.searchValue);
  }
}
